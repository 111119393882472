
import { BlockPeriod, IGenericError } from '@libs/entity-lib';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromCustomer from './block-period.actions';

export interface State extends EntityState<BlockPeriod> {
    loading: boolean;
    error: IGenericError | null;
}

const adapter: EntityAdapter<BlockPeriod> = createEntityAdapter<BlockPeriod>({
  selectId: (blockPeriod: BlockPeriod) => blockPeriod.id
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});


export const reducer = createReducer(
  initialState,
  on(fromCustomer.fetchBlockPeriod, (state) =>  ({ ...state, loading: true, error: null })),
  on(fromCustomer.fetchBlockPeriodSuccessfuly, (state, { blockPeriodList }) => {
    return adapter.addMany(blockPeriodList, {
      ...state,
      loading: false,
      error: null
    })
  }),
  on(fromCustomer.fetchBlockPeriodFailed, (state, { error }) =>  adapter.setAll([], { ...state, loading: false, error })),

  // Add
  on(fromCustomer.addBlockPeriod, (state) =>  ({ ...state, loading: true, error: null })),
  on(fromCustomer.addBlockPeriodSuccessfuly, (state, { blockPeriod }) => {
    return adapter.addOne(blockPeriod, {
      ...state,
      loading: false,
      error: null
    })
  }),
  on(fromCustomer.addBlockPeriodFailed, (state, { error }) =>  adapter.setAll([], { ...state, loading: false, error })),

// Update
on(fromCustomer.updateBlockPeriod, (state) =>  ({ ...state, loading: true, error: null })),
on(fromCustomer.updateBlockPeriodSuccessfuly, (state, { blockPeriod }) => {
  const update: Update<BlockPeriod> = {
    id: blockPeriod.id, 
    changes: { ...blockPeriod }
  };

  return adapter.updateOne(update, {
    ...state,
    loading: false,
    error: null
  });
}),
on(fromCustomer.updateBlockPeriodFailed, (state, { error }) =>  adapter.setAll([], { ...state, loading: false, error })),


  // Remove
  on(fromCustomer.removeBlockPeriod, (state) =>  ({ ...state, loading: true, error: null })),
  on(fromCustomer.removeBlockPeriodSuccessfuly, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      loading: false,
      error: null
    })
  }),
  on(fromCustomer.removeBlockPeriodFailed, (state, { error }) =>  adapter.setAll([], { ...state, loading: false, error }))
);

export const { selectAll } = adapter.getSelectors();